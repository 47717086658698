<template>
  <div class="attachments">
    <div v-for="(el,index) in fileList" :key="index" class="file-item">
      <viewer v-if="!(['.zip','.pdf','.doc','.docx','.dwg','.rar'].includes(el.name.slice(el.name.lastIndexOf('.'))))" :ref="'viewer' + index" style="display: inline-block;vertical-align: middle">
        <img :src="el.url" onerror="this.src='https://reconova-test.oss-cn-zhangjiakou.aliyuncs.com/AIOS/miniprogram/pic/default.png';this.οnerrοr=null"/>
      </viewer>
      <img v-else :src="getDefaultImg(el.name)">
      <div class="msg">
        <p class="fontBold" v-ellipsis-text:middle>{{el.name}}</p>
        <p class="sizeTip">{{el.size}}</p>
      </div>
      <div class="tool">
        <span v-if="!(el.name.indexOf('.zip')>-1||
                      el.name.indexOf('.dwg')>-1||
                      el.name.indexOf('.rar')>-1)" @click="preview(el,index)" class="blue">预览</span>
        <span v-if="!canDelete" @click="download(el)" class="blue">下载</span>
        <span v-if="canDelete" @click="deleteFile(el)" class="colorRed">删除</span>
      </div>
    </div>
<!--    <div v-if="fileList.length === 0">暂无数据</div>-->
    <viewer ref="pdfListViewer" class="preview"  :images="currentViewPhotos">
      <img v-for="(src,index)  in currentViewPhotos" :src="src" :key="index">
    </viewer>

    <!--  预览doc/docx  -->
    <div  class="wordfile" v-if="wordFileUrl" @click="closeIframe">
      <iframe :src="wordFileUrl" width='60%' height='90%' frameborder='0'/>
    </div>
  </div>

</template>

<script>
  import {previewPDF} from "@/utils/utils";
  import ellipsisText from '@/directive/ellipsis-text'
  import {aDownload} from "@/utils/utils";

  export default {
    name: "attachments",
    directives: { ellipsisText },
    props: {
      fileList: {
        type: Array,
        default() {
          return []
        }
      },
      canDelete:{
        type:Boolean,
        default() {
          return false;
        }
      }
    },
    data() {
      return {
        currentViewPhotos: [],
        userInfo:null,
        wordFileUrl:''
      }
    },
    methods: {
      download(file) {
        this.$axios.get(file.url, {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'no-cache', // 解决下载OSS文件偶现跨域问题
          },
        }).then(res => {
          aDownload(res.data,file.name)
        });
      },
      preview(file, index) {
        const fileType = file.url.substr(file.url.lastIndexOf(".")+1)
        // 如果是
        if (file.isPicturePreview) {  // 预览pdf
          previewPDF(file).then(res=>{
            this.currentViewPhotos = res;
            this.$refs.pdfListViewer.$viewer.show()
          })
        }else if(['doc','docx'].includes(fileType)) {
          this.wordFileUrl = "https://view.officeapps.live.com/op/view.aspx?src="+encodeURIComponent(file.url)
        }else {
          this.$refs["viewer" + index][0].$viewer.show()
        }
      },


      deleteFile(file){
        for (let i = 0; i < this.fileList.length; i++) {
          if (file.name === this.fileList[i].name) {
            this.fileList.splice(i, 1);
            this.$emit("update:fileList",this.fileList)
            break
          }
        }
      },

      closeIframe(){
        this.wordFileUrl = ''
      },

      getDefaultImg(fileName){
        const splitIndex = fileName.lastIndexOf('.');
        const type = fileName.slice(splitIndex)
        if(['.zip','.rar','.pdf'].includes(type)){
          return require(`@/assets/${fileName.slice(splitIndex+1)}.png`)
        }else{
          return require('@/assets/file_default.png')
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .attachments {
    padding: 10px;
    max-height: 200px;
    overflow: auto;
    width: 400px;

    >div{
      width: 100%;

      img{
        height: 32px;
        width: 32px;
        vertical-align: middle;
      }

      .msg{
        display: inline-block;
        margin-left: 20px;
        width: 200px;
        text-align: left;
        vertical-align: middle;
        p{
          margin-top: 5px;
          line-height: 20px;
          overflow: hidden;
          cursor: pointer;

          &.sizeTip{
            font-size: 12px;
            color: #8c939d
          }
        }
      }


      .tool{
        width: 100px;
        margin-left: 40px;
        vertical-align: top;
        line-height: 60px;
        display: inline-block;

        span{
          margin-left: 20px;
        }
      }
    }
  }
  .file-item{
    cursor: pointer;
  }
  .rejectApplyModal .attachments .file-item{
    width: auto;
    margin-left: 80px;
  }
  .attachments >div:hover .msg{
    color: #2973FF;
  }
  .preview{
    display: none;
  }
  .wordfile{
    position: fixed;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.2);
    iframe{
      margin-left: 20%;
      margin-top: 2%;
    }
  }
</style>
