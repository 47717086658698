<template>
  <!-- 实现审核提交 -->
  <div class="checkDetail">
    <el-form :model="form" :rules="formRule" ref="form" label-width="80px" class="add-form">
      <el-form-item label="店铺" prop="shopId">
        <el-select filterable class="width360" v-model="form.shopId" @change="changeValue" placeholder="请选择" size="medium" :disabled="form.status==3">
          <el-option-group
                  v-for="group in shopList"
                  :key="group.id"
                  :label="group.name">
            <el-option
                    v-for="el in group.childList"
                    :key="el.id"
                    :label="el.name"
                    :value="el.id">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="业态">
        <div class="width360 textRight">{{businessName}}</div>
      </el-form-item>
      <el-form-item label="附件" prop="fileList">
        <el-upload
	            ref="upload"
	            class="upload-demo"
	            drag
	            action="/business/process/upload"
	            accept=".png,.jpg,.jpeg,.pdf,.dwg,.zip,.rar"
	            :show-file-list="false"
	            :on-change="changeFile"
	            :http-request="uploadFile"
	            :before-upload="beforeUpload"
	            :limit="limitFileNum"
	            :on-exceed="overFile"
            :multiple="true">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip fontBold" slot="tip">请上传jpg, png, pdf,zip,rar文件，且不超过100M,最多上传5个文件<br><span class="colorRed">注意：上传cad文件时，请附带上传pdf文件</span></div>
        </el-upload>
        <attachments class="enclosure" :fileList.sync="form.resources" :canDelete="true"></attachments>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
                :maxlength="80"
                style="width: 360px"
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.description">
        </el-input>
      </el-form-item>
    </el-form>
    <div class="detail_item">
      <label>流程</label>
      <div class="diagram-content">
        <flow :flows.sync="activities" :status="form.status"></flow>
      </div>
    </div>
    <div class="occupy-ele"></div>
    <div class="detail_btn">
      <el-button size="medium" @click="cancel">取消</el-button>
      <el-button class="btn-reset" size="medium" @click="resetForm" v-if="form.status==6">重置</el-button>
      <el-button size="medium" :loading="isSubmit" @click="confirm" type="primary">提交</el-button>
    </div>
  </div>
</template>

<script>
  import Enclosure from "./attachments";
  import {
    getFloorShop,
    getModel,
    processUpload,
  } from "../../../../services/check";
  import {Loading} from "element-ui";
  import uploadCommon from "../../../../utils/upload";
  import Attachments from "./attachments";
  import {isIE, validateApprover} from "../../../../utils/utils";
  import Flow from "../../../../components/flow";

  export default {
    name: "checkAdd",
    components: {Flow, Enclosure,Attachments},
    props: {
      typeId: {
        type: Number,
        default() {
          return 1
        }
      },
      editId:{
        type:Number,
        default(){
          return 0
        }
      }
    },
    data() {
      let validateAttach = (rule,value,callback)=>{
        if(this.form.resources.length===0){
          callback(new Error('附件不能为空'))
        }else{
          callback()
        }
      }
      return {
        timer:null,
        shopList: [],
        tagList: [],
        imageUrl: "",
        dialogVisible: false,
        isSubmit:false,
        isEdited:false,
        businessName:'',
        activities: [],
        form: {
          description: "",
          resources: [],
          shopId: "",
          id:"",
        },
        formRule: {
          shopId: [{required: true, message: '请选择商铺', trigger: 'change'}],
          fileList:[{required:true,validator:validateAttach,trigger:['blur','change']}]
        },
        loadingOption: {
          lock:true,
          text:'文件上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        },
        uploadFileNum:0
      }
    },
    watch:{
      form:{
        handler:function(newval,oldVal) {
          if(newval.id&&oldVal.id!==''){
            this.isEdited=true;
            if(newval.status!==3){
              if(this.timer){
                clearTimeout(this.timer)
              }
              this.timer = setTimeout(this.updateDraft,500)
            }
          }
        },
        deep:true
      }
    },
    computed:{
      limitFileNum(){
        return 5-this.form.resources.length
      }
    },
    methods: {
      // 查询草稿
     queryDraft(){
        this.$api.check.getDraft({typeId:this.typeId}).then(res=>{
          if(res.data&&res.data.id){ // 如果有草稿 就编辑草稿
            this.form = res.data;
            this.form.resources = res.data.resources||[];
            if(res.data.shopId){
              this.changeValue(res.data.shopId)
            }
          }else{ // 如果没有草稿 就新增草稿
            this.addDraft()
          }
        })
      },

      // 新增草稿
      async addDraft(){
        this.form.status=6;
        let param = JSON.parse(JSON.stringify({...this.form,typeId:this.typeId}))
        this.$api.check.addDraft(param).then(res=>{
          this.form.id = res.data.id
        })
      },

      // 更新草稿
      updateDraft(){
        // 如果用户已经点击了提交按钮，这个时候应该return;
        if(this.isSubmit||this.form.status==3){
          return ;
        }
        let param = {
          description:this.form.description,
          id:this.form.id,
          resources:this.form.resources||[],
          shopId:this.form.shopId
        }
        this.$api.check.updateDraft({...param,typeId:this.typeId}).then(res=>{

        })
      },
      // 重置草稿
      async resetForm(){
        let data = {
          resources: [],
          shopId: "",
          id:this.form.id,
          status:6
        }
        this.$api.personnel.reset({typeId:this.typeId}).then(res=>{
          this.form=data;
          this.getModel();
        })
      },

      //覆盖原有的上传逻辑
      async uploadFile(data) {
        const file = data.file;
        // 如果是cad和pdf文件不需要压缩，直接上传
        //上传给后端，拿到url
        let form = new FormData;
        const type = file.type.split('/')[1]
        let ie = isIE();
        if(['png', 'jpg','jpeg'].includes(type)&&!ie){
          const img = await uploadCommon.readImg(file);
          const blob = await uploadCommon.compressImg(img,500, 500,file.type);
          form.append("file", new window.File([blob], file.name, {type: file.type}));
        }else{
          form.append("file",file)
        }
        form.append("biz", this.typeId);
        let loading = Loading.service(this.loadingOption);
        this.uploadFileNum++;
        processUpload(form).then(res => {
          this.uploadFileNum--;
          if(this.uploadFileNum==0){
            loading.close();
          }
          if(this.form.resources.length<5){
            this.form.resources.push({
              name: res.data.fileName,
              url: res.data.fileUrl,
              size: res.data.fileSize,
              id: res.data.id,
              isPicturePreview:res.data.isPicturePreview
            })
          }else{
            this.form.resources.splice(-1,1,{
              name: res.data.fileName,
              url: res.data.fileUrl,
              size: res.data.fileSize,
              id: res.data.id,
              isPicturePreview:res.data.isPicturePreview
            })
          }
          this.$refs.upload.clearFiles()
        })
      },

      overFile(files,fileList){
        if(files.length<=(5-this.form.resources.length)){
          return true
        }else{
          this.$message.warning(`最多可上传5个附件,当前最多可选择 ${5-this.form.resources.length} 个附件`);
          this.$refs.upload.clearFiles()
          return false
        }
      },

      changeFile(file,fileList){
        this.$nextTick(()=>{
          this.$refs.form.clearValidate('fileList')
        })
      },

       beforeUpload(file) {
        if(this.form.resources.length<5){
          //验证文件格式和大小最大100M
          return uploadCommon.checkFileAndSize(file,100);
        }else{
          this.$message.error("最多上传5个文件");
          return false;
        }

      },

      changeValue(event){
        for(let i=0;i<this.shopList.length;i++){
          for(let k=0;k<this.shopList[i].childList.length;k++){
            if(this.shopList[i].childList[k].id==event){
              this.businessName = this.shopList[i].childList[k].businessName
              break;
            }
          }
        }
      },

      cancel(flag) {
        this.$emit("cancel", flag)
      },

      confirm() {
        // 驳回待修改状态的数据 必须修改表单之后才能提交
        if(!this.isEdited&&this.form.status==3){
          return this.$message.error('表单内容未修改，请修改后提交')
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let node = JSON.parse(JSON.stringify(this.activities))
            if (!validateApprover(node)) {
              return;
            }
            this.isSubmit = true;
            let param = Object.assign({},this.form)
            param.activityNodeDetails = node;
            param.typeId = this.typeId;
            let handler = this.form.status==3?this.$api.check.reSubmitApply(param):this.$api.check.submitApply(param);
            handler.then(res => {
              this.$message.success("提交成功");
              this.isSubmit = false;
              this.cancel(true)
            }).catch(err=>{
              this.isSubmit = false;
            })
          }
        });
      },

      getShopList() {
        //店铺搜索框
        getFloorShop().then(res => {
          this.shopList = res.data.childList
        })
      },
      //获取流程相关
      getModel(){
       return new Promise((resolve,reject)=>{
         getModel({type: this.typeId}).then(res => {
           if(res.code === 200){
             this.activities = res.data.nodeList
           }
           resolve(res.data.nodeList)
         }).catch(err=>{
           reject(err)
         })
       })
      },

      // 获取详情
      getDetail(id) {
        this.$api.check.getDecDetail({id:id}).then((res) => {
          this.form = res.data;
          this.activities  = JSON.parse(JSON.stringify(res.data.activityModel.activitiNodeDetails));
          if(res.data.shopId){
            this.changeValue(res.data.shopId)
          }
        })
      },

    //  初始化
      async init(){
        await this.getShopList();
        if (this.editId) {  // 驳回待修改编辑
          this.getDetail(this.editId)
        }else{
          await this.getModel();
          this.queryDraft();
        }
      }
    },
    created() {
      this.init();
    }
  }
</script>

<style scoped lang="scss">
  .checkDetail {
    background: white;
    height: 100%;
    overflow: inherit;
    .occupy-ele{
      height: 75px;
    }
    .add-form{
      padding: 16px;
    }
    .detail_btn {
      padding: 15px 15px 30px 15px;
      position: fixed;
      bottom: 0;
      left: 260px;
      right: 20px;
      background: #fff;
      text-align: center;
    }
    .detail_item{
      padding: 16px;
      background: #fff;
      label{
        width: 80px;
        display: inline-block;
        text-align: right;
        box-sizing: border-box;
        padding-right: 12px;
        height: 34px;
        line-height: 34px;
      }
      .diagram-content{
        display: inline-block;
        margin-left: 15px;
        vertical-align: text-top;
        padding: 5px 0;

      }
    }
  }

  .upload-demo{
    width: 360px;
    display: inline-block
  }
  ::v-deep .el-timeline-item__node{
    background: #c7c7c7;
  }
  ::v-deep .el-select--medium{
    width: 360px !important;
  }
  ::v-deep.el-select--medium .el-input--medium{
    width: 100% !important;
  }
  ::v-deep .el-upload__tip{
    color: #a1a2a2 !important;
    margin-top: 0 !important;
}

</style>
